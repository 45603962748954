import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Navbar from './components/Navbar';

import ErrorPage from 'pages/ErrorPage';
import DORAMetrics from 'pages/WellArchitecturedProjectsSurvey/DORAMetrics';
import EngineeringSurveyView from './pages/EngineeringSurveyView';
import Team from './pages/Team';
import ProjectResponseDetailView from './pages/WellArchitecturedProjectsSurvey/ProjectResponseDetailView';
import ProjectResponseGeneralView from './pages/WellArchitecturedProjectsSurvey/ProjectResponseGeneralView';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Navbar />
      <Routes>
        <Route
            path='/'
            element={<EngineeringSurveyView yearOfResults={2025} />}
        />
        <Route
          path='/2024'
          element={<EngineeringSurveyView yearOfResults={2024} />}
        />
        <Route
          path='/2023'
          element={<EngineeringSurveyView yearOfResults={2023} />}
        />
        <Route path='/team' element={<Team />} />
        <Route
          path='/well-architected-projects-survey/project/:id/:year'
          element={<ProjectResponseDetailView />}
        />
        <Route
          path='/well-architected-projects-survey/:year?'
          element={<ProjectResponseGeneralView />}
        />
        <Route path='/dora-report' element={<DORAMetrics />} />
        <Route path='*' element={<ErrorPage />}></Route>
      </Routes>
    </MsalAuthenticationTemplate>
  );
}

export default App;
