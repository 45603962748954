export const msalConfig = {
  auth: {
    clientId: '96849c2f-b172-4436-89ab-8ec63096cc8a',
    authority:
      'https://login.microsoftonline.com/7c359f6b-9f2f-4042-bf2d-b08ce16c5cc1', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
/**
 * Surveys results list IDs:
 * 2023: '4b89d6c5-c510-4756-b7d1-165d406c5d6f'
 * 2024: '21eddf50-37a8-4ee4-8d91-c9ed86b850a1'
 * 2025: '77adb236-ca49-4ff5-a730-206db912d055'
 */
export const graphConfig = {
  graphColumnEndpoint:
    'https://graph.microsoft.com/v1.0/sites/2a6480dd-89ea-4a99-a8cb-137f349a846e,5e00ef0e-4940-4377-97f2-a420d8d64242/lists/77adb236-ca49-4ff5-a730-206db912d055?expand=columns',
  graphItemsEndpoint:
    'https://graph.microsoft.com/v1.0/sites/2a6480dd-89ea-4a99-a8cb-137f349a846e,5e00ef0e-4940-4377-97f2-a420d8d64242/lists/77adb236-ca49-4ff5-a730-206db912d055/items?$expand=fields'
};
