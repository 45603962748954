import { getQuestionsWithAnswersForGeneralView } from '../questionsProvider';

// 2023
import questionsDataSOE2023 from 'api/soe/2023/questionsData.json';
import sharePointItemsJsonSOE2023 from 'api/soe/2023/sharePointItemsData.json';
import reportPdf2023 from 'reports/2023_engineering_survey_report.pdf';

// 2024
import questionsDataSOE2024 from 'api/soe/2024/questionsData.json';
import sharePointItemsJsonSOE2024 from 'api/soe/2024/sharePointItemsData.json';

// 2025
import questionsDataSOE2025 from 'api/soe/2025/questionsData.json';

export const getStaticDataForGeneralView = (yearOfSurvey) => {
  const { questionItems, questionsData } = getStaticSurveyData(yearOfSurvey);
  return getQuestionsWithAnswersForGeneralView({
    questionItems,
    questionsData
  });
};

export const getStaticSurveyData = (yearOfSurvey) => {
  switch (yearOfSurvey) {
    case 2025:
      // TODO: add pdf file of 2024 survey.
      return {
        questionsData: questionsDataSOE2025,
        reportFile: undefined
      };
    case 2024:
      return {
        questionItems: sharePointItemsJsonSOE2024,
        questionsData: questionsDataSOE2024,
        reportFile: undefined
      };
    case 2023:
      return {
        questionItems: sharePointItemsJsonSOE2023,
        questionsData: questionsDataSOE2023,
        reportFile: reportPdf2023
      };
    default: {
    }
  }
};
