import { useEffect, useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import Hero from 'components/Hero';
import ResultsCard from 'components/ResultsCard';
import { getQuestionsWithAnswersForGeneralView } from '../questionsProvider';
import { getItems } from '../auth/graph';
import surveyMetadata from 'api/soe/surveyMetaData.json';

import {
  getStaticDataForGeneralView,
  getStaticSurveyData
} from 'util/staticDataUtils';

export const PAST_YEARS_OF_SURVEY = [2023, 2024];

const EngineeringSurveyView = ({ yearOfResults }) => {
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);
  const [questionItems, setQuestionItems] = useState();

  const { instance, accounts } = useMsal();

  const { surveyName, description, reportLinkText, formLink, formLinkText } =
    surveyMetadata[yearOfResults] || {};

  const surveyData = getStaticSurveyData(yearOfResults);

  const fetchResults = useCallback(async () => {
    let questionsWithAnswers;

    /** Static data from json */
    if (PAST_YEARS_OF_SURVEY.includes(yearOfResults)) {
      setQuestionItems(surveyData.questionItems);
      questionsWithAnswers = getStaticDataForGeneralView(yearOfResults);
    } else {
      /** Dynamic data from API */
      const accessTokenRequest = {
        scopes: ['user.read'],
        account: accounts[0]
      };

      await instance
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          const items = await getItems(accessTokenResponse.accessToken);
          setQuestionItems(items);

          questionsWithAnswers = getQuestionsWithAnswersForGeneralView({
            questionItems: items,
            questionsData: surveyData.questionsData
          });
        });
    }

    setQuestionsWithAnswers(questionsWithAnswers);
  }, [instance, accounts, yearOfResults, surveyData]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults, yearOfResults]);

  return (
    <div className='page-background'>
      <Hero
        title={surveyName}
        description={description}
        reportLinkText={reportLinkText}
        reportFile={surveyData.reportFile}
        formLink={formLink}
        formLinkText={formLinkText}
      />
      <ResultsCard
        questionsWithAnswers={questionsWithAnswers}
        questionItems={questionItems}
      />
    </div>
  );
};

export default EngineeringSurveyView;
