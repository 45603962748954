import soeTeamData from 'api/team/soeTeamData.json';
import wapsTeamData from 'api/team/wapsTeamData.json';
import useIsMobile from 'util/hooks/useIsMobile';
import Heading from './Heading';
import TeamMember from './TeamMember';
import clsx from 'clsx';

const TeamList = () => {
  const { general, communication, data, development } = soeTeamData;
  const { development: wapsDevTeam, general: wapsGeneral } = wapsTeamData;
  const isMobile = useIsMobile();

  const renderTeamGrid = (members) => {
    if (!members?.length) return null;
    
    const count = members.length;
    const maxCols = count >= 3 ? 3 : count;
    const fullRows = Math.floor(count / maxCols);
    const remainingItems = count % maxCols;
    
    return (
      <div className="mt-8 mb-12 space-y-8">
        {/* Full rows with maxCols items */}
        {fullRows > 0 && (
          <div className={clsx(
              'grid gap-8 grid-cols-1',
              `lg:grid-cols-${maxCols}`
          )}>
            {members.slice(0, fullRows * maxCols).map((member, idx) => (
              <TeamMember {...member} key={`full-${idx}`} />
            ))}
          </div>
        )}
        
        {/* Last row with remaining items centered */}
        {remainingItems > 0 && (
          <div className={clsx(
            'grid gap-8 grid-cols-1',
            `lg:grid-cols-${remainingItems}`,
            `lg:w-${remainingItems}/3`,
            'lg:mx-auto'
          )}>
            {members.slice(fullRows * maxCols).map((member, idx) => (
              <TeamMember {...member} key={`last-${idx}`} />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='w-full min-h-screen pt-20 pb-3 sm:pb-18 px-4 flex flex-col justify-between page-background'>
      <div className='max-w-5xl m-auto px-4 bg-white border border-slate-300 rounded-md shadow-xl'>
        <div className='p-8'>
          <Heading className='py-3 text-2xl md:text-3xl lg:text-4xl font-bold text-center text-black'>
            Nortal State of Engineering team
          </Heading>

          {/* Core team */}
          {renderTeamGrid(general)}

          {/* Communication team */}
          {renderTeamGrid(communication)}

          {/* Data Analysis */}
          {renderTeamGrid(data)}

          <Heading className='py-3 text-2xl lg:text-3xl font-bold text-center text-black mt-8'>
            Form and results page development
          </Heading>

          {/* Development team */}
          {renderTeamGrid(development)}
        </div>

        <div className='px-8 pb-8'>
          <Heading className='py-3 text-2xl md:text-3xl lg:text-4xl font-bold text-center text-black'>
            Well Architected Projects Team
          </Heading>

          {/* WAPS General team */}
          {renderTeamGrid(wapsGeneral)}

          {/* WAPS Development team */}
          {renderTeamGrid(wapsDevTeam)}
        </div>
      </div>
    </div>
  );
};

export default TeamList;
