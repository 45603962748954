import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

const yearOptions = [
  { value: '2025', label: '2025' },
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' }
];

const customSelectStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    minHeight: '24px',
    padding: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      border: 'none'
    }
  }),
  singleValue: (base) => ({
    ...base,
    color: 'white',
    margin: 0,
    paddingLeft: '0'
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    color: '#00A651',
    cursor: 'pointer',
    padding: '8px 16px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      color: '#008741'
    }
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '4px',
    marginTop: '4px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    animation: 'slideDown 0.2s ease-out',
    overflow: 'hidden'
  }),
  menuList: (base) => ({
    ...base,
    padding: '4px 0'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'white',
    padding: 0,
    marginLeft: 0,
    transition: 'transform 0.2s ease',
    '&:hover': {
      color: 'white'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0',
    margin: 0,
    flex: 'none',
    '@media (min-width: 1024px)': {
      padding: '0 4px 0 16px'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0
  })
};

const YearSelector = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  
  // Default to highest year if no year selected
  const currentYear = pathname === '/' ? '2025' : pathname.replace('/', '');
  const selectedOption = yearOptions.find(op => op.value === currentYear) || yearOptions[0];

  const handleYearChange = (option) => {
    navigate(option.value === '2025' ? '/' : `/${option.value}`);
  };

  return (
    <div className="w-17">
      <Select
        value={selectedOption}
        onChange={handleYearChange}
        options={yearOptions}
        styles={customSelectStyles}
        isSearchable={false}
      />
    </div>
  );
};

export default YearSelector;
